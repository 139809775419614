/* Card hover animations */
.news-card {
  transition: all 0.3s ease;
}

.news-card:hover {
  transform: translateY(-5px);
}

/* Image zoom effect */
.zoom-image {
  transition: transform 0.5s ease;
}

.zoom-container:hover .zoom-image {
  transform: scale(1.1);
}

/* Gallery image hover effect */
.gallery-image::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-image:hover::after {
  opacity: 1;
}
