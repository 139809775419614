/* Service card hover effects */
.service-card {
  transition: all 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
}

/* Process number animations */
.process-number {
  transition: all 0.3s ease;
}

.process-item:hover .process-number {
  transform: scale(1.1);
}

/* Image hover effects */
.project-image {
  transition: all 0.5s ease;
}

.project-image:hover {
  transform: scale(1.05);
}
