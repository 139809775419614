/* Form input animations */
.form-input {
  transition: all 0.3s ease;
}

.form-input:focus {
  transform: translateY(-2px);
}

/* Contact info hover effects */
.contact-info-item {
  transition: all 0.3s ease;
}

.contact-info-item:hover {
  transform: translateY(-2px);
}

.contact-info-item:hover .icon-container {
  background-color: theme("colors.primary.600");
  color: white;
}

/* Map overlay animation */
.map-container::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), transparent);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.map-container:hover::after {
  opacity: 1;
}
